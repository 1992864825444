@import "./_reset.scss";
@import "./_vars.scss";
@import "./_fonts";

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-height: 2px;
$hamburger-layer-width: 20px;
$hamburger-layer-spacing: 4px;
$hamburger-active-layer-color: var(--color-secondary);
$hamburger-layer-color: #fff;

$hamburger-types: (spin);

:root {
  --color-primary: #292929;
  --color-secondary: #ea475c;
  --scroll-bar-size: 8px;
}
@import "hamburgers/_hamburgers.scss";
@import "MatterportFont.scss";

@import "Main";

@import "BaseTour";
@import "PublicAnalytics.scss";
@import "ConferenceTour.scss";
@import "ECommerceTour.scss";
@import "GameTour";
@import "MP.scss";
@import "GameBookBtn";
@import "BottomBar.scss";
@import "Appointment.scss";
@import "BookAppointmentAsset.scss";
@import "ClassicPublicAsset.scss";
@import "FloorPlan.scss";
@import "GuideModal.scss";
@import "HorizontalAccordions.scss";
@import "Keypad.scss";
@import "LeftMenuNav.scss";
@import "Login.scss";
@import "Menu.scss";
@import "Minimap.scss";
@import "Panorama.scss";
@import "Share.scss";
@import "SlickPublicAsset.scss";
@import "Summary.scss";
@import "Sweep.scss";
@import "TagPopup.scss";
@import "CollectLeads.scss";
@import "Video.scss";
@import "pathFinderMesh.scss";
@import "MenuMesh.scss";

