.pf-mesh{
  &-wrapper{
    display: flex;
    flex-direction: column;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    border-radius: 20px;

    width: 940px;
    height: 180px;
    padding: 18px;

    z-index: -1;
    opacity: 0.5;
    font-family: 'Codec-Pro', sans-serif;
  }

  &-header{
    display: flex;
    font-weight: bold;
    font-size: 14px;
  }

  &-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 18px;
    text-align: left;
    font-size: 14px;
    max-width: 600px;
    &-description {
      color: #636363;
      margin-bottom: 10px;
    }
    &-step-count {
      color: #333333;;
      height: 14px;
    }
  }

  &-footer{
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  &-button {
    width: 444px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 68px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #2055FF;

    &:focus{
      border: 1px solid #2055FF;
    }

    &--back {
      margin-right: 3px;
      background-color: white;
      color: #2055FF;

    }
    &--next{
      margin-left: 3px;
      background-color: #2055FF;
      color: white;
    }
  }
}
